
<div class="all" >
  <div class="overlay" [class.active]="showValidationPopup"></div>
  <div class="container">
    <div class="image" style="width: 34%;">
      <div style="display: flex; justify-content: center; flex-direction: column;margin-top: 7%;">
        <div style="display: flex;
        justify-content: center;">
          <img src="../../assets/logo-n.png" style="position: relative;
          /* top: 10%; */
          /* display: flex; */
          justify-content: center;
          width: 37%;
          ">
        </div>
        <div style="margin: 12% 0;
        /* width: 65%; */
        display: flex;
        justify-content: center;">
          <img src="../../assets/Image_RC_Etudiant_PREVAS.png" style="width: 60%;
          position: relative;
          /* top: 35%; */
          /* left: 11%;*/
          "> 
        </div>
      </div>
    <div class="text">
      <div class="footer">
        <h3>Vous pouvez nous contacter sur l'email suivant :</h3>
        <!-- <input style="height: 46px;" type="submit" class="button" value="Validerrrrrrrrrrr" (click)="submitForm222()"> -->
        <p>
           <a style="color: #cecece;text-decoration: underline;" href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a>
        </p>
        <p>
          Tél : <a style="color: #cecece;">05 22 020 903</a> /
           <a style="color: #cecece;">05 22 020 908</a>
        </p>
      </div>
    </div>





     </div>
    <div class="responsive">
      <div style="display: flex;
      justify-content: center;">
        <img src="../../assets/logo-n.png" style="position: relative;
        /* top: 10%; */
        /* display: flex; */
        justify-content: center;
        width: 30%;
        ">
        </div>
       
    </div>
 <div class="login-wrap">
  
  <div class="top"><i style="margin: 0px 10px 0 0;
    font-size: 18px;
    padding: 6px 0;" class="fas fa-graduation-cap"></i>Espace étudiant</div>
<div class="login-html">
  <input  id="tab-1" type="radio" name="tab" [(ngModel)]="selectedTab" value="signIn" class="sign-in" checked><label for="tab-1" class="tab" >Connectez-vous</label>
  <input id="tab-2" type="radio" [(ngModel)]="selectedTab" value="signUp" name="tab" class="sign-up"><label for="tab-2" class="tab">Création
          de compte</label>
          
          <div class="form-container" [class.flip]="selectedTab === 'signUp'">
            <div class="login-form">
              <div class="sign-in-htm"  *ngIf="selectedTab === 'signIn'">
                <form [formGroup]="form" (ngSubmit)="login($event)" style="border: 1px solid #e5e5e5;padding: 20px 27px;border-radius: 2px;">
                  <div class="group" style="display: block;margin-bottom:9px;">
                    <label for="user" class="label">Votre adresse email</label>
                    <input type="text" class="input" (focus)="onchangeemaillogin()" formControlName="email" name="email" required>
                    <p *ngIf="form.get('email')?.hasError('required') && submitted" class="description">Email est obligatoire</p>
                    <p *ngIf="form.get('email')?.hasError('email') && submitted" class="description">email invalid</p>
                  </div>
                  <div class="group" style="display: block;margin-bottom:9px;">
                    <label for="pass" class="label">Mot de passe</label>
                    <input [type]="changepass ? 'password' : 'text'" (focus)="onchangepass2()" class="input" formControlName="password" name="password" required>
                  </div>
                  <p *ngIf="form.get('password')?.hasError('required') && submitted" class="description">Le mot de passe est obligatoire</p>
                  <div class="group">
                    <input id="check" type="checkbox" class="check" required (click)="changepassword()">
                    <label style="cursor: pointer;" for="check"><span class="icon"></span> Afficher le mot de passe</label>
                  </div>
                  <div class="captchaInput" style="margin: 3% 0;">
                    <ngx-recaptcha2
                      #captchaElem
                      [siteKey]="siteKey"
                      (reset)="handleReset()"
                      (expire)="handleExpire()"
                      (load)="handleLoad()"
                      (success)="handleSuccess($event)"
                      [useGlobalDomain]="false"
                      [size]="size"
                      [hl]="lang"
                      [theme]="theme"
                      formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <p *ngIf="form.get('recaptcha')?.hasError('required') && submitted" style="margin-top: 8px;" class="description">Veuillez compléter le CAPTCHA pour continuer</p>
                  </div>
                  <div class="group" style="justify-content: flex-end;margin: 17px 0 -10px;">
                    <input type="submit"  class="button" value="Connexion" (click)="onSubmit()">
                  </div>
                </form>
                <div class="foot-lnk">
                  <a class="visible" routerLink="/password" (click)="scrollToTop()">Cliquer ici pour réinitialiser votre mot de passe</a>
                  <a class="visible" routerLink="/desactiver-compte" (click)="scrollToTop()">Cliquez ici pour désactiver votre compte</a>
                  <a class="visible" routerLink="/contact" (click)="scrollToTop()">Cliquez ici pour nous contacter</a>
                  <a class="visible" href="./../../../assets/Mentions Légales.pdf"  target="_blank">Mentions Légales</a> 

                  <!-- <a class="visible" routerLink="faq" (click)="scrollToTop()">FAQ : Questions Fréquemment Posées</a> -->
                </div>
                <div class="responsive" style="background: transparent;padding: 0;border-top: 1px solid #c1c1c1;
                   margin-top: 15px;">
                  <p style="color: #000000;
                       margin: 3px 6px;
                       font-size: 14px;
                       font-weight: 600;">Vous pouvez nous contacter sur l'email suivant: <a href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a></p>
                      <p style="color: #000000;
                      margin: 5px 6px;
                      font-size: 14px;
                      font-weight: 600;">
                        Tél : <a >05 22 020 903</a> / <a >05 22 020 908</a>
                      </p>
               </div>
              </div>

              <div class="sign-up-htm" *ngIf="selectedTab === 'signUp'">
                <form action="" (ngSubmit)="register(form2.value)" #clientForm="ngForm" [formGroup]="form2" style="border: 1px solid #e5e5e5;padding: 20px 27px;border-radius: 2px;">
                  <div class="group">
                    <div class="input-group">
                      <label for="user" class="label">Votre CIN</label>
                      <input appAllowedLetters type="text" (focus)="onchangecin()" name="cin" formControlName="cin" class="input">
                      <p *ngIf="form2.get('cin')?.hasError('required') && submitted2" class="description">Le numéro CIN est obligatoire</p>
                      <p *ngIf="form2.get('cin')?.hasError('minlength') && submitted2" class="description">Le numéro CIN doit avoir au moins 4 caractères</p>
                    </div>
                    <div class="input-group">
                      <div class="cne">
                        <input appAllowedLetters id="bac" type="checkbox" [checked]="bacetranger" (click)="onchangebacetranger($event)">
                        <label for="bac" style="text-transform: uppercase;color: #363535;">Bac étranger</label>
                      </div>
                      <label for="user" class="label">Votre CNE</label>
                      <input style="background: rgb(223 223 223 / 10%); border: 1px solid #f5f3f3;" *ngIf="bacetranger" type="text" name="cne" class="input" disabled>
                      <input  appAllowedLetters *ngIf="!bacetranger" (focus)="onchangecne()" id="pass"  type="text" name="cne" formControlName="cne" class="input" maxlength="10" required>
                      <p *ngIf="form2.get('cne')?.hasError('required') && submitted2 && !bacetranger" class="description">Le numéro CNE est obligatoire</p>
                      <p *ngIf="form2.get('cne')?.hasError('pattern') && submitted2 && !bacetranger" class="description">Le CNE doit comporter exactement 10 caractères</p>
                    </div>
                  </div>
                  <div class="group">
                    <div class="input-group">
                      <label for="pass-repeat" class="label">VOTRE NOM</label>
                      <input appAllowedLetters type="text" name="nom" (focus)="onchangenom()" class="input" formControlName="nom">
                      <p *ngIf="form2.get('nom')?.hasError('required') && submitted2" class="description">Le nom est obligatoire</p>
                      <div *ngIf="form2.get('nom')?.hasError('pattern') && submitted2" style="color: #da0e0e;" class="description">Le nom ne doit contenir que des lettres, sans chiffres</div>
                    </div>
                    <div class="input-group">
                      <label for="email" class="label">VOTRE PRÉNOM</label>
                      <input appAllowedLetters type="text" name="prenom" (focus)="onchangeprenom()" class="input" formControlName="prenom">
                      <div style="color: #da0e0e;" class="description" *ngIf="form2.get('prenom')?.hasError('required') && submitted2">Le prénom est obligatoire</div>
                      <div style="color: #da0e0e;" class="description" *ngIf="form2.get('prenom')?.hasError('pattern') && submitted2">Le prénom ne doit contenir que des lettres, sans chiffres</div>
                    </div>
                  </div>
                  <div class="group">
                    <div class="input-group">
                      <label  for="pass-repeat" class="label">VOTRE E-MAIL</label>
                      <input appAllowedLetters type="email"  (paste)="preventPaste($event)" name="email" (focus)="onchangeemail()"  class="input" formControlName="email">
                      <p *ngIf="form2.get('email')?.hasError('email') && submitted2" class="description">L'adresse e-mail que vous avez saisie n'est pas une adresse e-mail valide.</p>
                      <p *ngIf="form2.get('email')?.hasError('required') && submitted2" class="description">L'adresse e-mail est obligatoire.</p>
                    </div>
                    <div class="input-group">
                      <label for="email" class="label">CONFIRMATION D'E-MAIL</label>
                      <input appAllowedLetters type="text"  (paste)="preventPaste($event)" class="input"  formControlName="confirmationemail">
                      <p style="color: red;" *ngIf="form2.hasError('emailMismatch') && submitted2" class="description">Ce n'est pas la même adresse e-mail.</p>
                    </div>
                  </div>
                  <div class="group">
                    <div class="input-group">
                      <label for="pass-repeat" class="label">MOT DE PASSE</label>
                      <input appAllowedLetters type="password" name="password" (paste)="preventPaste($event)" maxlength="13" (focus)="onchangepass()" class="input" formControlName="password" data-type="password">
                      <p *ngIf="form2.get('password')?.hasError('pattern') && submitted2" class="description"> Le mot de passe doit contenir au moins 6 caractères, 1 lettre et 1 chiffre.</p>
                      <p *ngIf="form2.get('password')?.hasError('required') && submitted2" class="description">Le mot de passe est obligatoire.</p>
                      <p class="error-message" *ngIf="form2.get('password')?.hasError('maxlength')  " class="description">
                        Le mot de passe ne peut pas dépasser 12 caractères.
                      </p>
                    </div>
                    <div class="input-group">
                      <label for="email" class="label">Confirmation du mot de passe</label>
                      <input appAllowedLetters type="password" class="input" formControlName="confirmpassword">
                      <p *ngIf="form2.hasError('passwordMismatch') && submitted2" class="description">Ce n'est pas le même mot de Passe.</p>
                    </div>
                  </div>
                  <div class="group">
                    <div class="input-group">
                      <label for="pass-repeat" class="label">TÉLÉPHONE</label>
                      <!-- (input)="onInputChange($event)" -->
                      <input   type="text"  formControlName="telephone"        (keypress)="restrictNonNumeric($event)"
                       (focus)="onchangetelephone()" name="telephone" class="input">
                      <p *ngIf="form2.get('telephone')?.hasError('required') && submitted2" style="color: #da0e0e;" id="telephone" class="description">Le numéro de téléphone est obligatoire</p>
                      <p *ngIf="form2.get('telephone')?.hasError('pattern') && submitted2" class="description">Le numéro de téléphone doit commencer par 0 suivi de 9 chiffres</p>

                      <!-- <p *ngIf="form2.get('telephone')?.hasError('minlength')" style="color: #da0e0e;" id="telephone" class="description">Le numéro de téléphone doit avoir au moins 10 caractères</p> -->
                    </div>
                    <div class="input-group">
                      <label for="email" class="label">ADRESSE</label>
                      <input appAllowedLetters appAllowedLetters type="text" class="input" (focus)="onchangeadresse()" formControlName="adresse" name="adresse">
                      <p *ngIf="form2.get('adresse')?.invalid && submitted2" class="description">Adresse est obligatoire</p>
                    </div>
                  </div>
                  <div class="group" style="display: block;margin-right: 10px; ">
                    <label style="padding: 6px 0;color:  #363535;font-size: 12px;" for="ville">VILLE</label>
                    <mat-form-field style="border: 1px solid #d8d8d8; border-radius: 4px; background: rgb(192 192 192 / 10%); width: 100%;" appearance="fill">
                      <mat-select style="font-family: 'Noir Pro';font-size: 14px;" formControlName="ville" (change)="onchangeville()" placeholder="Sélectionnez votre ville">
                        <mat-option>
                          <ngx-mat-select-search [(ngModel)]="searchTerm" placeholderLabel="Recherche..." noEntriesFoundLabel="'Aucune ville trouvée'" [ngModelOptions]="{standalone: true}" (input)="searchCities()">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let city of listville | async" [value]="city.ville" style="font-family: 'Noir Pro';font-size: 12px;">
                          {{ city.ville }}
                        </mat-option>
                      </mat-select> 
                    </mat-form-field>
                    <p *ngIf="form2.get('ville')?.invalid && submitted2" class="description">Veuillez selectioner votre ville</p>
                    <div class="check-2">
                      <input (click)="change()" type="checkbox" id="Checkbox" (change)="checknoxapprouvechanged($event)" required>
                      <!-- <label style="text-align: justify;" for="Checkbox">J'approuve que les informations figurant ci-dessus sont correctes et conformes à la réalité.<br> Les informations recueillies sur ce formulaire font l'objet d'un traitement par Prevas</label> -->
                      <label style="text-align: justify;" for="Checkbox">
                        Par le biais de ce formulaire, PREVAS Assurance collecte vos données personnelles en vue de la souscription à une assurance Responsabilité Civile (RC) étudiant. Ce traitement a fait l'objet d'une demande d'autorisation auprès de la CNDP sous le numéro <b> A-W-569/2024 </b>.

                        Les données personnelles collectées peuvent être transmises à notre compagnie d'assurance partenaire et à notre prestataire de paiement situés au Maroc, afin de réaliser l'opération de souscription et de paiement.
<br>
                        Vous pouvez vous adresser à notre service client (05 22 020 903 et 05 22 020 908) ou via notre espace assuré pour exercer vos droits d'accès, de rectification et d'opposition conformément aux dispositions de la loi 09-08.
                      </label>
                    </div>
                  </div>
                  <div class="group grp" style="justify-content: space-between;margin-right: 10px;align-items: center;">
                    <div class="foot-lnk" (click)="scrollToTop()">
                      <label for="tab-1" class="connect_footer" >Vous avez déjà un compte ? <div style="color: #29234A;">&nbsp;Se connecter..</div></label>
                    </div>
                    <input style="height: 46px;" type="submit" [class.disable]="!form2.valid || !isapprouved" class="button" value="Valider" (click)="submitForm();onSubmit2()">
                  </div>
                  <div class="responsive" style="background: transparent;padding: 0;border-top: 1px solid #c1c1c1;
                     margin-top: 15px;">
                    
                      
                      
                    <p style="color: #000000;
                         margin: 5px 0px;
                         font-size: 14px;
                         font-weight: 600;">Vous pouvez nous contacter sur l'email suivant: <a href="mailto:reclamation@prevas.ma">reclamation@prevas.ma</a></p>
                      <p style="color: #000000;
                      margin: 10px 0px;
                      font-size: 14px;
                      font-weight: 600;">
                        Tél : <a >05 22 020 903</a> / <a >05 22 020 908</a>
                      </p>
                 </div>
                </form>
              </div>
            </div>
          </div>
          
</div>
</div>

</div>
<div *ngIf="showValidationPopup" class="popup-container" >
  <div style="position: absolute;
  right: 5%;
  font-size: 19px;
  color: white;
  top: 14px;" (click)="closeValidationPopup()">
    <i  class="fas fa-times"></i>
  </div>
 
<h2>ATTENTION !</h2>
<div style="padding: 10px 20px;">
<p style="color: black;text-align: justify;">Veuillez noter que les informations ci-dessous sont utilisées pour valider votre inscription auprès de votre établissement universitaire et ne pourront plus être modifiées après votre commande :</p>
<ul>
  <li><strong>CIN :</strong> {{form2.get('cin')?.value}}</li>
  <li *ngIf="bacetranger === false"><strong>CNE :</strong> {{form2.get('cne')?.value}}</li>
  <li *ngIf="bacetranger === true"><strong>CNE :</strong></li>
  <li><strong>Nom :</strong> {{form2.get('nom')?.value}}</li>
  <li><strong>Prénom :</strong> {{form2.get('prenom')?.value}}</li>
</ul>
<div class="confirmation">
  <input type="checkbox" id="confirmInfo" [(ngModel)]="validationcheck" >
  <label for="confirmInfo">Confirmation des informations</label>
</div>
<div class="buttons">
  <button [style.background-color]="buttonColor()" (click)="submitValidationForm()">Valider</button>
  <button (click)="closeValidationPopup()">Annuler</button>
</div>
</div>
</div> 
</div>



